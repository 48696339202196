/**
 * mobilo JS
 *
 * siehe webpack.mix.js
 *
 */

require('../app.js');

$('.slick').slick({
    centerMode: true,
    centerPadding: '30px',
    slidesToShow: 1,
    autoplay: true,
    accessibility: false,
    touchMove:false,
    swipe:false,
    autoplaySpeed: 0,
    speed: 4000,
    cssEase:'linear',
    arrows: false,
    variableWidth: true,
    infinite: true,
});
